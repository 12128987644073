/* Animation */
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


/*  Styling  */
#logos-home-page .slider {
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#logos-home-page .slider:before,#logos-home-page .slider:after {
		background: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0) 100%);
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
#logos-home-page .slider:after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

#logos-home-page .slider:before {
		left: 0;
		top: 0;
	}
	#logos-home-page .slide-track {
		animation:scroll 40s linear infinite;
		display: flex;
		width: calc(370px * 14);
	}
	
#logos-home-page div.slide {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}