@import url('https://fonts.googleapis.com/css2?family=Mr+De+Haviland&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mr+De+Haviland&family=Poppins:wght@500&display=swap');
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */
.activity-contents {
    justify-content: center;
    align-items: baseline;
    margin-top: 5%;
}

.activity-title {
    padding-inline: 18%;
    padding-right: 49%;
    margin-top: 6%;
    margin-bottom: 5%;

}

.activity-how-we-work {
    /* font-weight: 600;
    text-align: center;
    color: #504b4b;
    font-size: 30px;
    text-transform: capitalize;
    padding-inline: 14%;
    font-family: 'Times New Roman';
    padding-right: 43%;
    margin-left: 620px; this line cuases the empty space to the rigth*/

    font-weight: 600;
    text-align: center;
    color: #504b4b;
    font-size: 30px;
    text-transform: capitalize;
    padding-inline: 14%;
    font-family: 'Times New Roman';
    
}
.activity-options-items {
    background-color: rgb(224, 224, 221);
    transition: all .3s ease-in-out;
    font-size: 43px;
    font-weight: 900;
    padding-block: 0px;
    padding-inline: 10px;
    color: rgb(255, 255, 255);
    box-shadow: 10px 5px 14px #b9b9b9;
    cursor: pointer;
    margin-top: 10%;

    width: 60px;
}

.activity-text {
    /* text-align: left;
    font-size: 27px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #504b4b;
    padding-inline: 14%;
    padding-right: 40%;
    margin-bottom: 2%;
    margin-top: 1%;
    margin-left: 46px; this line also cause empty space to the right*/
/*************************************************************/
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #504b4b;
    padding-inline: 14%;
    margin-bottom: 2%;
    margin-top: 1%;
}


.activity-left-section {
    width: 10%;
    margin-inline: 0%;
    justify-content: space-evenly;
}


.activity-options-items:hover {
    /* width: 100%; */
    width: 60px;
    background: linear-gradient(-90deg,
            var(--primary-app-color),
            var(--secondary-app-color));
    color: #fff;
}



.selected {
    background: linear-gradient(-45deg, #4a58ff, var(--secondary-app-color));
    /* width: 100%; */
    color: #fff;
    width: 60px;
}

.activity-head{
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #504b4b;

}
.activity-discription {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    text-align: justify;
}
.activity-mob{
    flex-direction: column;
}



/* @media (max-width: 500px) {
    .activity-text {
        font-family: sans-serif;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 2px;
        text-align: center;
        color: #1c1c1c;
    }

    .resume-text-sub {
        font-family: 'Courier New', Courier, monospace;
        font-size: 0.7rem;
        font-weight: 800;
        opacity: .8;
        text-transform: capitalize;
        text-align: center;
        color: #1c1c1c;
    }

    .activity-left-section {
        width: 100%;
        margin-inline: auto;
    }

  

    .activity-options-items {
        padding-block: 14px;
        padding-inline: 40px;
        font-size: 13px;
    }


} */

@media all and (max-width: 786px)  {
    .activity-how-we-work {
        /* text-align: left;
        color: rgb(27, 92, 212);
        font-size: 15px;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: 'Mr De Haviland', cursive;
        padding-right: 43%; */

        text-align: center;
        color: rgb(27, 92, 212);
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: "Times New Roman";



        /* font-weight: 600;
        text-align: center;
        color: #504b4b;
        font-size: 30px;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: 'Times New Roman'; */
        
    }

    .activity-text {
        /* text-align: left;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        padding-inline: 14%;
        padding-right: 40%;
        margin-bottom: 2%; */

        text-align: center;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        padding-inline: 14%;
        margin-bottom: 2%;

    }

    .activity-options-items {
        background-color: rgb(224, 224, 221);
        transition: all .3s ease-in-out;
        font-size: 30px;
        font-weight: 900;
        padding-block: 0px;
        padding-inline: 10px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin-top: 10%;
        width: 50px;
        
    }

    .activity-options-items:hover {
        /* width: 100%; */
        width: 50px;
        background: linear-gradient(-90deg,
                var(--primary-app-color),
                var(--secondary-app-color));
        color: #fff;
    }

    .activity-head{
        text-align: left;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
    
    }
    .activity-discription{
        /* font-size: 11px;
        font-family: 'Poppins', sans-serif;
        font-weight: 200; */

        font-size: 11px;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
    
         
        
    }
    .activity-left-section {
        width: 10%;
        margin-inline: 0%;
        justify-content: space-evenly;
    }
    .activity-mob{
         flex-direction: column;
    }
    .selected{
        width: 50px;
    }
}


/********/
@media all and (max-width: 767px)  {
    .activity-how-we-work {
        /* text-align: left;
        color: rgb(27, 92, 212);
        font-size: 15px;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: 'Mr De Haviland', cursive;
        padding-right: 43%; */

        text-align: center;
        color: #504b4b;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: "Times New Roman";



        /* font-weight: 600;
        text-align: center;
        color: #504b4b;
        font-size: 30px;
        text-transform: capitalize;
        padding-inline: 14%;
        font-family: 'Times New Roman'; */
        
    }

    .activity-text {
        /* text-align: left;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        padding-inline: 14%;
        padding-right: 40%;
        margin-bottom: 2%; */

        text-align: center;
        font-size: 17px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        padding-inline: 14%;
        margin-bottom: 2%;

    }

    .activity-options-items {
        background-color: rgb(224, 224, 221);
        transition: all .3s ease-in-out;
        font-size: 30px;
        font-weight: 900;
        padding-block: 0px;
        padding-inline: 10px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin-top: 10%;

        width: 50px;
    }

    .activity-options-items:hover {
        /* width: 100%; */
        width: 50px;
        background: linear-gradient(-90deg,
                var(--primary-app-color),
                var(--secondary-app-color));
        color: #fff;
    }

    .selected{
        width: 50px;
    }
    
    .activity-head{
        text-align: left;
        font-size: 15px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
    
    }
    .activity-discription{
        /* font-size: 11px;
        font-family: 'Poppins', sans-serif;
        font-weight: 200; */

        font-size: 11px;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
    
         
        
    }
    .activity-left-section {
        width: 10%;
        margin-inline: 0%;
        justify-content: space-evenly;
    }
    .activity-mob{
         flex-direction: row;
    }
    
}


/*******/








@media all and (max-width: 425px){
    .activity-how-we-work {
        text-align: center;
        color: #504b4b;
        font-size: 16px;
        text-transform: capitalize;
        padding-inline: 0%;
        font-family: "Times New Roman";
        padding-right: 0%;
    }
    .activity-text {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        color: #504b4b;
        padding-inline: 0;
        padding-right: 0;
        margin-bottom: 2%;
    }
    .activity-mob{
        flex-direction: row;
    }
    .activity-options-items {
        background-color: rgb(224, 224, 221);
        transition: all .3s ease-in-out;
        font-size: 20px;
        font-weight: 900;
        padding-block: 3px;
        padding-inline: 10px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin-top: 10%;
        margin-bottom: 10%;

        width: 50px;
    }

    .activity-options-items:hover {
        /* width: 100%; */
        width: 50px;
        background: linear-gradient(-90deg,
                var(--primary-app-color),
                var(--secondary-app-color));
        color: #fff;
    }

    .selected{
        width: 50px;
    }
}