.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 11px;
}


@media(max-width: 790px){
    .whats-app {
        position: fixed;
        width: 45px;
        height: 45px;
        bottom: 20px;
        right: 7.5px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }

    .my-float {
        margin-top: 7px;
    }
}


@media(max-width: 500px){
    .whats-app {
        position: fixed;
        width: 30px;
        height: 30px;
        bottom: 20px;
        right: 7.5px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 20px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }

    .my-float {
        margin-top: 7px;
    }
}