.bg-img {
    background-image: url('../../Images/footerImg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(187, 196, 210);
}

.comp-logo {
    width: 20%;
    border-radius: 50%;

}

.iframe{
    /* width: 526px;
    height: 314px;
    border: 5px;
    border-color: grey; */

  
    border: 5px;
    border-color: grey;
}

.custom-search {
    /* position: relative;
    width: 300px; */

    position: relative;
}

.custom-search-input {
    color: #fff;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 100px;
    background-color: #282726;
    padding: 10px 100px 10px 20px;
    line-height: 1;
    box-sizing: border-box;
    outline: none;
}

.custom-search-botton {
    position: absolute;
    right: -3px;
    top: 0px;
    bottom: 0px;
    border: 0;
    background: #333192;
    color: #fff;
    outline: none;
    margin: 0;
    padding: 8px 22px;
    border-radius: 100px;
    z-index: 2;
}

.footer-discription{
    font-size: 14px;
    text-align: justify;
    padding: 5%;
}

.custom-search {
    /* position: relative;
    width: 300px;
    margin-bottom: 5%; */

    position: relative;
    margin-bottom: 5%;
}



.desc-footer{
    /* color: rgb(172, 168, 168);
    margin-left: 23px;
    margin-top: -19px; */

    color: rgb(172, 168, 168);
    margin-left: 23px;
    margin-top: -19px;
}
.logo-footer{
    /* color: rgb(172, 168, 168);
    width: 20%;
    margin-left: 104px; */

    color: rgb(172, 168, 168);
    width: 20%;
    margin-left: 10%;
}


@media all and (max-width: 425px){
    .footer-discription{
        font-size: 10px;
        text-align: justify;
        padding: 5%;
    }
    .footer-signup{
        font-size: 12px;
        margin-bottom: -10px;
    }
    .custom-search-input {
        height: 30px;
        color: #fff;
        width: 61%;
        border: 1px solid #ccc;
        border-radius: 100px;
        background-color: #282726;
        padding: 10px 48px 10px 10px;
        line-height: 1;
        font-size: 10px;
        box-sizing: border-box;
        outline: none;
    }
    .custom-search-botton {
        /* position: absolute;
        right: 55px;
        top: 0px;
        bottom: 0px;
        border: 0;
        font-size: 10px;
        background: #333192;
        color: #fff;
        outline: none;
        margin: 0;
        padding: 4px 8px;
        border-radius: 100px;
        z-index: 2; */

        position: absolute;

        top: 0px;
        bottom: 0px;
        border: 0;
        font-size: 10px;
        background: #333192;
        color: #fff;
        outline: none;
        margin: 0;
        padding: 4px 8px;
        border-radius: 100px;
        z-index: 2;
    }
    
}