

.loading-logo{
    width:200px;
    height: 200px;
    border-radius:50% ;
    margin-top:25vh;

    animation: zoom-in-zoom-out 1.5s ease infinite;
    
}

@keyframes zoom-in-zoom-out {
    0% {
    transform: scale(1, 1);
    }
    50% {
    transform: scale(1.5, 1.5);
    }
    100% {
    transform: scale(1, 1);
    }
    }