.event-bg-img {
    background-image: url('../../Images/Forevents.jpg');
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    
  
}

.event-section {
    padding: 3%;
}

.event-head {
    text-align: center;
}

.event-title {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: 'Times New Roman';
    text-transform: capitalize;
}



.event-sub-title {

    text-align: center;
    color: white;
    font-size: 23px;
    line-height: 0%;
    font-weight: 400;
    text-transform: uppercase;
}

.num-design {
    font-size: 50px;
    font-weight: 700;
    color: #a0d324;
}

.event-table {
    font-size: 30px;
    margin-left: 5%;
    font-weight: 300;
    color: #d7d8d3;
    text-align: center;

    border-bottom-width: 0px;
}

.event-items {
    width: 25%;
}

.event-logo-img {
    /* width: 100%;
    padding: 20%;
    height: 121px;
    border-radius: 50%; */

    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.event-img {
    width: 100%;
    height: 495px;
    border-radius: 1%;
}

.table-description {
    font-weight: 700;
    font-size: 20px;
    line-height: 2;
    text-transform: capitalize;
}

.table-content {

    width: 70%;
}

.table-date {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 10%;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: #fff;
}

.table>:not(caption)>*>* {
    width: 30px;
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

@media all and (max-width: 786px) {
    .event-title {
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: -3px;
        font-family: 'Times New Roman';
        text-transform: capitalize;
    }

    .event-items {
        width: 25%;
        justify-content: flex-start;
    }
    .event-img {
        width: 100%;
        height: 250px;
        border-radius: 1%;
    }
    .event-logo-img {
        /* width: 100%;
        height: 66px;
        padding: 20%;
        border-radius: 50%; */

        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .table-content {
        width: 70%;
        margin-top: 7%;
    }

    .table-date {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 10px;
        margin-top: 0%;
        font-family: 'Poppins', sans-serif;
        text-align: left;
    }

    .table-description {
        font-weight: 600;
        font-size: 12px;
        line-height: 2;
        text-transform: capitalize;
    }
}

@media all and (max-width: 425px) {
    /* .event-img {
        width: 0%;
        height: 100%;
        border-radius: 1%;

    } */

    .event-items {
        width: 37%;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
    }

    .event-logo-img {
        /* width: 100%;
        padding: 10%;
        margin-top: 25%;
        border-radius: 2%; */

        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .event-logo-dummy-img {
        width: 100%;
        padding: 10%;

        border-radius: 2%;
    }

    .event-title {
        text-align: center;
        color: white;
        font-size: 15px;
        margin-top: 8%;
        font-weight: 600;
        margin-bottom: -9px;
        font-family: 'Times New Roman';
        text-transform: capitalize;
    }

    .table-content {
        width: 70%;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .table-date {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 6px;
        margin-top: 5%;
        font-family: 'Poppins', sans-serif;
        text-align: left;
    }

    .table-description {
        font-weight: 600;
        font-size: 9px;
        line-height: 2;
        text-transform: capitalize;
    }

    .table>:not(caption)>*>* {
        width: 0px;
        margin-left: 10%;
        padding: 0.5rem 0.1rem;
        background-color: var(--bs-table-bg);
        border-bottom-width: 0px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }

    .event-table {
        font-size: 30px;
        margin-left: 17%;
        font-weight: 300;
        color: #d7d8d3;
        text-align: center;
        border-bottom-width: 0px;
    }

    /* .event-bg-img {
        min-height: 226px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 88%;
        margin-left: 6%;
    } */


}