.home-content{
    background-image: url("../../Images/flashImg.png");
    background-repeat: no-repeat;
    background-size: cover  ;

}
.flash-news-content {
    font-size: 20px;
    font-weight: 100;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}

.flashnews-para{
    height: 11px;
    font-family: 'Poppins', sans-serif;
}

.news-head{
    background-color: #1c1c1c;
}

.news-title{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
   
    color: #3c3199;

}

@media all and (max-width: 786px){
    .p-5 {
        padding: 1rem!important;
    }
    .news-title {
        font-size: 25px;        
    }
    
}

@media all and (max-width: 425px){
    .p-4 {
        padding: .5rem!important;
    }
    .news-title {
        font-size: 15px;
    }
    .flash-news-content {
        font-size: 7px;
        font-weight: 100;
        color: #ffffff;
    }
}

