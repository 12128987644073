

:root{
  --primary-app-color : #02203c;
  --secondary-app-color : #001528;
  --standout-color : #fffb00;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
strong {
  font-weight: bold;
}

p{
  font-family: 'Source Sans Pro', 'Lucida Grande', sans-serif;
}

*{
  padding: 0;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
