.nav-search {
    /* margin-bottom: -20px;
    margin-top: -2%;
    justify-content: flex-start;
    margin-left: -23%; */
    /*-----------------------*/
   
    justify-content: flex-start;
}

.searchbar {
    /* margin-bottom: auto;
    margin-top: auto;
    height: 40px;
    background-color: #353b48;
    border-radius: 40px;
    padding: 0; */

    /*------------------------------*/

    
   
    height: 100%;/*40px*/
    background-color: #353b48;
    border-radius: 40px;/*40px;*/
    padding: 0;
}

.search_input {
    /* color: white;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    caret-color: transparent;
    line-height: 40px;
    transition: width 0.4s linear; */

    /*------------------------------------------*/
    color: white;
    border: 0; 
     outline: 0;
    background: none;
    width: 0;
    caret-color: transparent;
    line-height: 40px;
    transition: width 0.4s linear; 
}

.searchbar:hover>.search_input {
    /* padding: 0 10px;
    width: 450px;
    caret-color: red;
    transition: width 0.4s linear; */
    /*---------------------------*/
    width: 80%;
    caret-color: red;
    transition: width 0.4s linear;
}

.searchbar:hover>.search_icon {
    background: white;
    color: #e74c3c;
}

.search_icon {
    /* height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-decoration: none; */

    /*-----------------------------*/

    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-decoration: none;
}


@media all and (max-width: 786px){

    /* .nav-search {
        margin-bottom: -9px;
        margin-top: -3%;
        justify-content: flex-start;
        margin-left: -23%;
    } */

    
    
    .searchbar {
        margin-bottom: auto;
        margin-top: auto;
        height: 25px;
        background-color: #353b48;
        border-radius: 40px;
        padding: 0;
        font-size: 10px;

        
    }
    .searchbar:hover>.search_input {
        /* padding: 0px 10px;
        width: 150px;
        font-size: 15px;
        caret-color: red;
        transition: width 0.4s linear; */

        width: 80%;
        caret-color: red;
        transition: width 0.4s linear;
        font-size: 10px;
        padding: 0%;
        margin: 0%;

    }
    .search_icon {
        height: 25px;
        width: 25px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
        text-decoration: none;
    }
    .search-icon{
        font-size: 12px;
    }
}


@media all and (max-width: 425px){

    .search_icon {
        height: 0px;
        width: 0px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
        text-decoration: none;
    }
    .search-icon{
        font-size: 0px;
    }
}