    .event-dummy{
    margin-top: 8%;
    margin-bottom: 15px;
   
}

.dummy-tittle{
    font-size: 20px;
}

.dummy-text{
    /* font-size: 16px; */
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-align: revert;

}

.text-section{
    
    padding-inline: 10%;
    text-align: justify;
   
}

@media (max-width: 786px){
    .dummy-text{
        
        font-size: 11px;
        /* padding-inline-start: 8%; */
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        
    }

    .text-section{
        text-align: justify;
    }
  
}

@media (max-width: 425px){
    .dummy-text {
        font-size: 8px;
        padding-inline: 5%;
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
    }
    .event-dummy{
        margin-top: 23%;
    }

     .dummy-tittle{
    font-size: 12px;
    }

    .text-section{
        text-align: justify;
    }
}
