

.facility-img{
    background-image: url('../../Images/facilityhomeimg.jpg');
    min-height: 500px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.facility-text{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #ffffff;
}

.facility-card{
    top: 250px;
    margin-inline: 20%;
}


.par-bg{
    background-image: url('../../Images/aboutbgImg.jpg'); 
}


@media all and (max-width: 786px){
    .facility-section {
        margin-top: 7%;
    }
}
@media all and (max-width: 425px){
    .facility-img {
        min-height: 250px;
        width: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

