/* .eom-offcanvas .eom-hamburger-wrap {
  border: 0px solid #dee1e5;
  top: 25px;
}

.eom-offcanvas .eom-hamburger-wrap:hover {
  border-color: #333192;
}
.eom-offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  width: 320px;
  height: 50%;
  background: #282c34;
  color: #eee;
  -webkit-transform: translate(-100%, 0);
  -moz-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  z-index: 9999;
}
.eom-offcanvas .eom-hamburger-wrap .eom-hamburger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 70%;
}

.eom-offcanvas .eom-menu-wrap .eom-menu-item {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  padding: 0px;
  color: #eee;
  font-size: 15px;
  text-decoration: none;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}


.eom-offcanvas .eom-hamburger-wrap:hover span {
  border-color: #333192;
}

.eom-offcanvas .eom-menu-wrap .eom-menu-item-wrap {
  position: relative;
  margin-top: 10%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
} */













/******************** new nav bar ****************/


.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1090;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  text-align: center;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}






/************************************************/












.navbar-brand {
  width: 15%;
}

.nav-comp-logo {
  /* width: 6.7%;
  border-radius: 50%;
  margin-left: 56%;
  margin-top: -76px;
  margin-bottom: -31px;
  box-shadow: 0px 0px 8px #000000;
  object-fit: fill; */

  /*------------------*/
  /* border-radius: 50%;
  box-shadow: 0px 0px 8px #000000;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
  image-rendering: optimizeQuality; */

  /* width: 20%; */
  border-radius: 50%;
  box-shadow: 0px 0px 8px #000000;
  object-fit: fill;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 70px;
  
}

.bars {
  height: 4px;
  width: 24px;
  background: #000000;
  float: right;
  z-index: 33;
  margin-inline: -30px;
  transform: translateX(-20px) translateY(-20px);
  margin-top: 25px;
  

}

.bars::before {
  display: block;
  position: relative;
  top: 9px;
  content: "";
  background: #000000;
  width: 24px;
  height: 4px;
}

.bars::after {
  display: block;
  position: relative;
  bottom: 12px;
  content: "";
  background: #000000;
  width: 24px;
  height: 4px;
}

.social-nav {
   /* margin-inline: 9%; 
   width: 15%; 
   height: 100%; 
  color: #fff;  */

  /*---------------------------*/

  width: 30%; 
  height: 20px; 
 color: #fff; 
}

.nav-icons {
  /* margin-top: -5%;
  padding: 0%;
  width: 11%;
  margin-left: 137%;
  height: 74px;
  background-color: #424243; */

  /*-----------------*/
 
  background-color: #424243;
}

.icon-bar {
  color: #fff;
}



.ml-auto {
  margin-left: auto !important;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0%;
  margin-bottom: 0;
  list-style: none;
  margin-left: -5%;
}

.nav-link:hover {
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(214, 26, 26);
  font-size: 0.85rem;

}

.nav-icon {
  width: 30px;
  height: 25px;
  margin: 2%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  color: black;
  background-color: #f8f9fa;

}

.nav-icon.span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #f8f9fa;
  border-radius: px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;

}

.register-link {
  /* text-align: end; 
  margin-top: -5%; */

  text-align: center; 


}

.register-text {
  /* text-transform: capitalize;    original
  font-size: 14px;
  font-weight: 400;
  margin-right: -35%;
  font-family: 'Poppins', sans-serif; */

  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

.register-btn {
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.navbar-brand {
  width: 15%;
}

.comp-logo {
  width: 100%;
}



.ml-auto {
  margin-left: auto !important;
}

.nav-link {
  text-transform: uppercase;
  font-weight: 700;
  color: #fff !important;
  font-size: 13px;

}


/* Medium devices (landscape tablets, 768px and up) */
@media all and (max-width: 786px) {
  .register-link {
    text-align: end;
    /* margin-top: -10%; */
  }

  .nav-search {
    /* margin-bottom: -20px;
    margin-top: -4%;
    justify-content: flex-start;
    margin-left: -23%; */


   
    justify-content: flex-start;
  }

  .nav-comp-logo {
    /* width: 12%;      
    border-radius: 50%;
    margin-left: 24%;
    margin-top: -43px;
    margin-bottom: 0px; */

    width: 60px;

    
   
  }

  .register-text {
    text-transform: capitalize;
    font-size: 12px;
    margin-right: 18%;
    font-family: 'Poppins', sans-serif;
  }

  .bars {
    height: 3px;
    width: 15px;
    background: #000000;
    float: right;
    z-index: 33;
    margin-inline: -26px;
    transform: translateX(-20px) translateY(-20px);
    margin-top: 26px;
  }

  .social-nav {
    width: 30%; 
    height: 20px; 
    color: #fff;
  }

  .bars::after {
    display: block;
    position: relative;
    bottom: 8px;
    content: "";
    background: #000000;
    height: 3px;
    width: 15px;
  }


  .bars::before {
    display: block;
    position: relative;
    top: 5px;
    content: "";
    background: #000000;
    height: 3px;
    width: 15px;
  }

  .galleryy-title {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-top: 13%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
  }

  .nav-link {
    text-transform: uppercase;
    font-weight: 600;
    color: #fff !important;
    font-size: 10px;

  }

  .nav-link:hover {
    text-transform: uppercase;
    font-weight: 700;
    color: rgb(214, 26, 26);
    font-size: 11px;
  }

  .nav-icons {
    margin-top: -8%;
    padding: 0%;
    width: 15%;
    margin-left: 83%;
    height: 72px;
    background-color: #424243;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0%;
    margin-bottom: 0;
    list-style: none;
    margin-left: -15%;
  }
}









@media all and (max-width: 425px) {


  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 15px;}




  .nav-icon {
    width: 19px;
    height: 22px;
    top: 8px;
    margin: 2%;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    color: black;
    background-color: #f8f9fa;
  }
  	

  .register-link {
    /* text-align: end;   ORIGINAL
    margin-top: -10%; */

    text-align: end;
    /* width: 20%; */
    /* margin-top: -10%; */
  }

  .nav-comp-logo {
    
    /* width: 29%;
     border-radius: 50%;
    margin-left: 41%;
    margin-top: -99px;
    margin-bottom: -31px; */

    width: 40px
    ;


    
   
  }

  .register-text {
    /* text-transform: capitalize;      ORIGINAL
    font-size: 0px;
    margin-right: 18%;
    font-family: 'Poppins', sans-serif; */

    text-transform: capitalize;
    font-size: 30%;
    /* margin-righ t: 18%; */
    font-family: 'Poppins', sans-serif;
  }


  .bars {
    height: 2px;
    width: 10px;
    background: #000000;
    float: right;
    z-index: 33;
    margin-inline: -25px;
    transform: translateX(-20px) translateY(-20px);
    margin-top: 25px;
  }

  .bars::after {
    display: block;
    position: relative;
    bottom: 5px;
    content: "";
    background: #000000;
    height: 2px;
    width: 10px;
  }


  .bars::before {
    display: block;
    position: relative;
    top: 3px;
    content: "";
    background: #000000;
    height: 2px;
    width: 10px;
  }

  .galleryy-title {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-top: 13%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
  }

  .nav-link {
    text-transform: uppercase;
    font-weight: 600;
    line-height: 0;
    color: #fff !important;
    font-size: 8px;
  }

  .nav-link:hover {
    text-transform: uppercase;
    font-weight: 700;
    color: rgb(214, 26, 26);
    font-size: 9px;
  }

  .nav-mobile {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: normal;
    justify-content: space-evenly;

  }

  .nav-icons {
    padding: 0%;
    width: 100%;
    height: 100%;
    background-color: #424243;
  }

  .search_icon {
    height: 0px;
    width: 0px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-decoration: none;
  }

  .social-nav {
    /* width: 15%; */
    width: 30%;
    height: 20px;
    color: #fff;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0%;
    margin-bottom: 0px;
    margin-top: 0%;
    list-style: none;

    margin-left: -21%;
  }






 




}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */



/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */






























/* 
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 10px;}
} */