.contact-section{
    padding: 50px 0;
    margin-top: 3%;
    background-color: #f7f7f7;
}

.searchbar.disabled {
    display: none !important;
    font-size: 0px !important;
    visibility: hidden;
    color: transparent !important;
  }

.contact-section-sub{
    margin-bottom: 10px;
    color: #939393;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

}

.contact-section-head{
    font-weight: 700;
    color: #232323;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    line-height: 60px;
    margin: 0 0 15px;
    padding: 0;
    letter-spacing: -1px;
    text-align: center;
    padding-inline: 20%;
    padding-bottom: 5%;

}
.contact-name{
    margin-left: 0;
    margin-top: 2px;
    color: darkgray;
    font-size: 500;
    font-weight: 900;
    font-size: 27px;
}    

.contact-img{
    width: 70%;
    height: 300px;
    margin: 10%;
    border-radius: 5px;

    margin-top: 22px;
}

.address-section{
    background-color: #1c1c1c;  
}
.head-address-section{
    background-color: #302d2d;
}

.address-title{
    margin-bottom: 5px;
    font-weight: 600;
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
    text-align: center!important;
}
.address-text-muted{

    text-align: center!important;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -font-smoothing: antialiased;
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    line-height: 24px;
}


@media(max-width:768px){

    .contact-img {
        height: 200px;
        width: 150px;
        margin: 5%;
        border-radius: 5px;
    }

    .contact-section-sub {
        font-size: 10px;
        line-height: 19px;
    }
    .contact-section-head {
        font-size: 20px;
        line-height: 55px;
        margin: 0 0 0px;
        padding: 0;
        padding-inline: 25%;
        padding-bottom: 5%;
    }
    .contact-location{
        height: 200px;
    }
    .address-title{
        margin-bottom: 0px;

    }
    .address-text-muted{

        font-size: 11px;
        color: #ffffff;
        font-weight: 400;
        line-height: 4px;
    }


    .contact-name{
        margin-top: 2px;
        color: darkgray;
        font-weight: 900;
        font-size: 15px;
        padding: 0;
        margin-left: 0;
    }    
    
}


@media all and (max-width: 425px){
    .contact-section-head {
        font-size: 15px;
        line-height: 25px;
        margin: 0 0 0px;
        padding: 0;
        padding-inline: 25%;
        padding-bottom: 5%;
    }
    .contact-section-sub {
        font-size: 7px;
        line-height: 17px;
    }
    .contact-img {
        height: 100px;
        width: 90px;
        margin: 5%;
        border-radius: 5px;
    }
    .contact-name {
        font-size: 8px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-top: 1%;
    }
    .contact-position{
        margin-bottom: 0px;
    }
    .address-text-muted {
        font-size: 7px;
        color: #ffffff;
        font-weight: 400;
        line-height: 0px;
        margin-bottom: 0px;
    }

    .form-title {
        color: #232323;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 0px;
        margin: 0 0 0px;
        padding: 0;
        letter-spacing: 0;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.5rem 0.5rem;
        font-size: 10px;
        font-weight: 400;
        line-height: 0.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn-hover-contact {
        background: transparent;
        border-color: #232323;
        color: #232323;
        font-size: 8px;
        padding: 4px 4px;
        line-height: 16px;
        margin-top: 0px;
        letter-spacing: .5px;
        border-radius: 0;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        white-space: nowrap;
    }
}