.gallery-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-family: "Times New Roman";
    margin-top: 5%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
}





.gallery-btn{
    background: white;
    color: black;
    border: 2px solid black;
    font-size: 13px;
    border-radius: 4px;
    text-align: center;
    /* padding: 20px 26px; */
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.4s;
    max-width: 100%;
    max-height: 100%;
    font-family: 'Times New Roman';
}


.gallery-section {
    margin-bottom: 5%;
}

/* .gallery-btn {
    margin-inline-start: 45.4%;
    margin-top: 5%;
    background: #000;
    color: #FFF;
    border: 2px solid white;
    font-size: 15px;
    border-radius: 4px;
    text-align: center;
    padding: 20px 26px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
} */

.gallery-btn-txt {
    color: #fff;
    text-decoration: none;

}

.gallery-btn:hover {
    background: black;
    color: white;
}

.gallery-main-img {
    border-radius: 10px;
    width: 500px;
    padding-top: 0px;
    height: 411px;
    margin-left: 56%;
}

.gallery-style {
    padding-inline: -10%;
}

.gallery-slide-img {
    margin-inline: -4%;
}





@media all and (max-width: 786px) {
    .gallery-title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        margin-top: 5%;
        text-transform: capitalize;
        color: #504b4b;
        margin-bottom: 2%;
    }

    /* .gallery-btn {
        margin-inline-start: 45.4%;
        margin-top: 5%;
        background: #000;
        color: #FFF;
        border: 2px solid white;
        font-size: 10px;
        border-radius: 4px;
        text-align: center;
        padding: 11px 11px;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.4s;

        margin-inline-start: ;
    
        

    } */
}

@media all and (max-width: 425px) {
    .gallery-title {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        margin-top: 5%;
        text-transform: capitalize;
        color: #504b4b;
        margin-bottom: 2%;
    }

    .gallery-btn{
        font-size: 8px;
    }

    /* .gallery-btn {
        margin-inline-start: 44%;
        margin-top: 3%;
        background: #000;
        color: #FFF;
        border: 2px solid white;
        font-size: 7px;
        border-radius: 4px;
        text-align: center;
        padding: 4px 4px;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.4s;
    } */

    .gallery-main-img {
        border-radius: 10px;
        width: 83%;
        padding-top: 6px;
        height: 98%;
        margin-left: 10%;
    }

    .imageStyle {
        margin: 6px;
        display: flex;
        cursor: pointer;
        width: 100px;
        /* height: 200px; */
        border-radius: 5px;
    }

    .gallery-contents {
        width: 0px;
        height: 0px;
    }
}











/******* Image Hover Effects ************/
.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 3%;
    object-fit: fill;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .delete-button{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 12%;
    left: 10%; 
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

  }

  .delete-text {
    background-color: #504b4b;
    color: red;
    font-size: 16px;
    padding: 8px 16px;
    font-style: italic;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .img-container:hover .image {
    opacity: 0.3;
  }
  
  .img-container:hover .middle {
    opacity: 1;
  }

  .img-container:hover .delete-button {
    opacity: 1;
  }
  
  .text {
    background-color: #504b4b;
    color: white;
    font-size: 16px;
    padding: 8px 16px;
    font-style: italic;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }


@media all and (max-width: 550px) {

    .text {
        background-color: #504b4b;
        color: white;
        font-size: 10px;
        padding: 4px 8px;
        font-style: italic;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      }

    
      .delete-text {
        background-color: #504b4b;
        color: red;
        font-size: 6px;
        padding: 3px 3px;
        font-style: italic;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      }

      .delete-button{
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 13%;
        left: 6%; 
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    
      }

}