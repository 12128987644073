.galleryy-title{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    font-family: 'Poppins', 'Times New Roman', Times, serif;
    margin-top: 13%;
    text-transform: capitalize;
    color: #504b4b;
    margin-bottom: 5%;
}


element.style {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}



.css-zjq1i3 {
    padding: 10%;
}

@media(max-width:500px){
    .galleryy-title {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Poppins', 'Times New Roman', Times, serif;
        text-transform: capitalize;
        color: #504b4b;
        margin-bottom: 5%;
        margin-top: 24%;
    }
    
}