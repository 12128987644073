$blackColor: #020304;

body {

  .headign-name {
    position: fixed;
    top: 40px;
    left: 150px;
    z-index: 6;
    font-size: 20px;
    margin-left: 9%;
    font-weight: 900;
    font-family: sans-serif;
    text-transform: uppercase;

    > .headign-sub-name {
      text-transform: none;
      opacity: .5;
      font-weight: 300;
      font-size: 12px;
    }
  }
  input + label {
    position: fixed;
    top: 26px;
    /* right: 34px; */
    height: 20px;
    left: 40px;
    width: 15px;
    z-index: 5;

      span {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        display: block;
        background: $blackColor;
        transition: .4s;
      }
      span:first-child {
        top: 3px; 
      }
      span:last-child {
        top: 16px; 
      }      
    }
    label:hover {
      cursor: pointer;
    }
    input:checked + label { 
      span {

        background-color: white;
        opacity: 0;
        top: 50%;
      }
      span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }
      span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
    input ~ nav {
      background: white;
      padding: 0px;
      box-shadow: 26px 4px 9px 2px #18171738;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 70px;
      z-index: 3;
      transition: 0.5s;
      transition-delay: 0.5s;
      overflow: hidden;
  
      > ul {
        text-align: center;
        position: absolute;
        top: 35%;
        left: 20%;
        right: 20%;
        > li {
          opacity: 0;
          transition: .5s;
          transition-delay: 0s;
          > a {
            text-decoration: none;
            text-transform: uppercase;
            color: $blackColor;
            font-weight: 700;
            font-family: sans-serif;
            display: block;
            padding: 30px;
          }
        }
      }
    }
    input:checked ~ nav { 
        height: 100%;
        transition-delay: 0s;
        background-color: black;
        opacity: 90%;
      > ul {
        > li {
          opacity: 1;
          transition-delay: .5s;
        }
      }
    } 
}