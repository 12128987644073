.form-title{
    color: #232323;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 36px;
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 15px;
    padding: 0;
    letter-spacing: 0;
    font-family: 'Poppins', sans-serif;
    margin-top: 39px;
}

.form-group {
    margin-bottom: 5%;
}

.btn-hover-contact {
    background: transparent;
    border-color: #232323;
    color: #232323;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    padding: 9px 34px;
    line-height: 25px;
    margin-top: 20px;
    letter-spacing: .5px;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
}

.btn-hover-contact:hover {
    background: #232323;
    border-color: #232323;
    color: #ffffff;
    font-size: 13px;
    padding: 9px 34px;
    line-height: 25px;
    text-decoration: none;
    cursor: pointer;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 13px;
    font-weight: 100;
    line-height: 1.5;
    font-family: 'Poppins', sans-serif;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


@media  all and (max-width:768px){
    .form-title {
        color: #232323;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 0px;
        margin: 0 0 0px;
        font-family: 'Poppins', sans-serif;
        padding: 0;
        letter-spacing: 0;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.5rem 0.5rem;
        font-size: 10px;
        font-weight: 400;
        line-height: 0.5;
        color: #212529;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn-hover-contact {
        background: transparent;
        border-color: #232323;
        color: #232323;
        font-size: 8px;
        padding: 4px 4px;
        font-family: 'Poppins', sans-serif;
        line-height: 16px;
        margin-top: 0px;
        letter-spacing: .5px;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 500;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        white-space: nowrap;
    }
    .btn-hover-contact:hover {
        background: #232323;
        border-color: #232323;
        color: #ffffff;
        font-size: 8px;
        padding: 4px 4px;
        font-family: 'Poppins', sans-serif;
        line-height: 16px;
        text-decoration: none;
        cursor: pointer;
    }
}


@media all and (max-width: 425px){

    .form-title {
        color: #232323;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 0px;
        margin: 0 0 0px;
        font-family: 'Poppins', sans-serif;
        padding: 0;
        letter-spacing: 0;
    }
    .form-control {
        display: block;
        width: 100%;
        padding: 0.5rem 0.5rem;
        font-size: 10px;
        font-weight: 400;
        line-height: 0.5;
        color: #212529;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .btn-hover-contact {
        background: transparent;
        border-color: #232323;
        color: #232323;
        font-size: 8px;
        padding: 4px 4px;
        font-family: 'Poppins', sans-serif;
        line-height: 16px;
        margin-top: 0px;
        letter-spacing: .5px;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 500;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        white-space: nowrap;
    }
    .btn-hover-contact:hover {
        background: #232323;
        border-color: #232323;
        color: #ffffff;
        font-size: 8px;
        padding: 4px 4px;
        font-family: 'Poppins', sans-serif;
        line-height: 16px;
        text-decoration: none;
        cursor: pointer;
    }
}